<template>
	<div>
		<s-crud
			title="Descarte y devolución (Verde - Corcho)"
			:config="config"
			:filter="filter"
			add
			edit 	
            remove
			search-input
            @rowSelected="rowSelected($event)"
            @save="save($event)"
		>
        <!-- remove -->
			<template v-slot="props">
				<v-container>
					<v-row>
                        <v-col cols="4" lg="3" md="4" sm="12">
                            <s-select-definition
                                label="Cultivo" 
                                :def="1173"
                                v-model="TypeCultive"
                                @input="getLines($event)"
                            ></s-select-definition>
                        </v-col>
                        <v-col cols="4" lg="3" md="4" sm="12">
                            <s-select
								:items="itemsLineas"
								item-value="ClpID"
								item-text="LncDescription"
								label="Lineas"
								v-model="ClpID"
                                ref="txtClpID"
                                @input="getTablesLine($event)"
                                clearable
                               
							>
							</s-select>
                        </v-col>
                        <v-col v-if="TypeCultive == 3" cols="4" lg="3" md="4" sm="12">
                            <s-select
								:items="itemTableDoll"
								item-value="DtbID"
								item-text="CttName"
								label="Estacion de Trabajo"
								v-model="DtbID"
                                ref="txtDtbID"
                                clearable
                                @input="getNumberLote($event)"
							>
							</s-select>
                        </v-col>
                        <v-col cols="4" lg="4" md="4" sm="12">
                            <s-select
								:items="itemRcfid"
								item-value="DtbID" 
								item-text="RcfDate"
								label="Numero de Lote"
                                v-model="newRcfID"
                                return-object
                                ref="txtRcfID"
                                clearable
                               
							>
                            <!-- @input="inputDamageTC($event)" -->
                            <!-- item-value="DtbID"  -->
                            <!-- v-model="props.item.RcfID" -->
							</s-select>
                        </v-col>
                        <v-col cols="4" lg="3" md="4" sm="12">          
                            <label slot="before" aria-hidden="true" class="v-label theme--light"><b>Hora Inicio Control Descarte</b></label>
                            <div class="relojito">
                                <datetime 
                                    title="Hora  :  Minutos" 
                                    valueZone="America/Lima"  
                                    type="time" 
                                    v-model="props.item.StartTimeCD"
                                    ref="txtStartTimeCD"
                                    >
                                </datetime>
                            </div>
                        </v-col>
                        <v-col cols="4" lg="3" md="3" sm="12">
                            <label slot="before" aria-hidden="true" class="v-label theme--light"><b>Hora Final Control Descarte</b></label>
                            <div class="relojito">
                                <datetime 
                                    title="Hora  :  Minutos" 
                                    valueZone="America/Lima"  
                                    type="time" 
                                    v-model="props.item.EndTimeCD"
                                    ref="txtEndTimeCD"
                                    >
                                </datetime>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" lg="4" md="4" sm="12">
                            <s-text
								label="Jabas de Verde Proceso"
                                decimal
								v-model="props.item.ProccesGreen"
                                ref="txtProccesGreen"
							>
							</s-text>
                        </v-col>
                        <v-col cols="4" lg="4" md="4" sm="12">
                            <s-text
								label="Peso (Kg) Jabas de Verde Proceso"
                                decimal
								v-model="props.item.ProcessGreenKG"
                                ref="txtProcessGreenKG"
							>
							</s-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="4" lg="4" md="4" sm="12">
                            <s-text
								label="Cajas de Corcho"
                                decimal
								v-model="props.item.Cork"
                                ref="txtCork"
							>
							</s-text>
                        </v-col>
                        <v-col cols="4" lg="4" md="4" sm="12">
                            <s-text
								label="Peso (Kg) Cajas de Corcho"
                                decimal
								v-model="props.item.CorkKG"
                                ref="txtCorkKG"
							>
							</s-text>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container>
                    <!--Mostrar Grupos Produccion-->
                    <v-row class="pt-3 pr-3 pl-3">
                        <v-spacer></v-spacer>
                        <v-divider></v-divider>
                        <v-container> 
                            <v-row>
                                <!--  -->
                                <!-- <v-col cols="3" >
                                    <s-select-definition
                                        label="Cultivo"
                                        v-model="props.item.TypeCultive"
                                        :def="1173"
                                        @input="inputDamageTC($event)"
                                        ref="txtTypeCultive"
                                    />
                                </v-col> -->
                                <v-col cols="4" lg="4" md="4" sm="12">
                                    <s-select
                                        label="Tipo de defecto"
                                        :items="DamageCultive"
                                        v-model="newItem.ObjTypeDefects"
                                        item-text="DfcName"
                                        item-value="DfcID"
                                        return-object
                                        @input="inputDamage($event)"
                                        ref="txtDamage"
                                    >
                                    </s-select>
                                </v-col>
                                <v-col cols="4" lg="4" md="4" sm="12">
                                    <s-text
                                        label="Valor (Kg)" 
                                        decimal
                                        v-model="newItem.Psgvalue"
                                        ref="focusFafValue"
                                    /> 
                                </v-col> 
                                <v-col>  
                                    <!--  -->
                                    <v-btn 
                                        rounded 
                                        fab 
                                        x-small 
                                        @click="addSampling()"
                                        ><i class="fas fa-plus"></i
                                    ></v-btn> 
                                </v-col>
                            </v-row>
                            <v-row> 
                                <v-col class="pl-0 pt-0">
                                    <v-data-table
                                        dense
                                        :headers="headersSampl"
                                        :items="itemsDataSampl"
                                        hide-default-footer
                                        :items-per-page="-1"
                                        disable-sort
                                        >
                                        <template v-slot:item.Actions="{ item }">
                                            <v-btn
                                                @click="clickDeleteDamage(item)"
                                                x-small
                                                fab
                                                
                                                color="error"
                                            >
                                            <v-icon style="font-size: 16px !important"
                                            >mdi-close</v-icon
                                            >
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-row>
                    <!--Fin de mostrar Grupos de Produccion-->
				</v-container>
			</template>
            <!-- <template v-slot:filter>
				<v-container>
                    <v-row justify="center">
                        <v-col cols="6">
                            <s-date
                                label="Fecha Inicial"
                                v-model="filter.DateBegin"
                                @input="loadItems()"
                            />
                        </v-col>
                        <v-col cols="6">
                            <s-date
                                label="Fecha Fin"
                                v-model="filter.DateEnd"
                                @input="loadItems()"
                            />
                        </v-col>
                        <v-col cols="6">
                            <s-select-definition
                                label="Cultivo"
                                :def="1173"
                                v-model="filter.TypeCultive"
                                @change="loadItems()"
                            />
                        </v-col>
                        <v-col cols="6">
                            <s-select-definition
                                label="Turno"
                                :def="1062"
                                :dgr="54"
                                v-model="filter.TypeTurn"
                                @change="loadItems()"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </template> -->
            
            <!-- <template v-slot:PdgStatus="{ row }">
				<v-chip x-small=""  :color="row.PdgStatus == 1 ? 'success': 'error'" >
					{{ row.PdgStatus == 1 ? 'Activo': 'Inactivo' }}
				</v-chip>
        	</template>

            <template v-slot:numberPerson="{ row }">
				<v-chip x-small=""  :color="row.numberPerson > 0 ? 'warning': 'error'" >
					{{ row.numberPerson }}
				</v-chip>
        	</template> -->

		</s-crud> 
	</div>
</template>



<script>
    import { Datetime } from 'vue-datetime';

    import _sDamageFlawCultiveService from "@/services/FrozenProduction/DamageFlawCultiveService";
    import _sProcessSampling from '@/services/FrozenProduction/ProcessSamplingService';

    import _sTurnService from "@/services/FreshProduction/PrfTurnService";
    import _sFrzCtnLinesProcessService from "@/services/FrozenProduction/FrzCtnLinesProcess.js"

	export default {
        components: { Datetime },
		data: () =>({
            itemRcfid:[],
            filter: {
                DateBegin:null,
                DateEnd:null,
                // TypeTurn:null,
                // TypeCultive:null
            },
            items : [],
            valueDamage: "",
            DfcName : "",
            TypeDefects:"",
            itemsDataSampl: [],
            headersSampl: [
                { text: "#", value: "Item" },
                { text: "Daño", value: "DfcName" } ,
                { text: "Valor", value: "Psgvalue" },  
                { text: "", value:"Actions"},
            ],
            DamageCultive: [],
            newItem: {},
            ObjDefects: {},
            itemSelected: [],
            itemPag:[],
            config: {
                model: {
                    PcsID: "ID",
                    // PdgStatus:"",
                    // numberPerson:""
                },
                service: _sProcessSampling,
                headers: [
                    {text: "ID", value: "PcsID"},
                    {text: "Numero de Lote", value: "RcfID"},
                    {text: "Tipo Cultivo", value: "TypeCultiveName"},
                    {text: "Linea", value: "LncDescription"},
                    {text: "Est. Trabajo", value: "CttName"},
                    {text: "Cajas de Verde Proceso", value: "ProccesGreen"},
                    {text: "Cajas de Verde Corcho", value: "Cork"},

                   
                ]
            },
            itemsDeleteDamage: [],
            StartTimeCD:"",
            Turn: "",
            itemsLineas: [],
            TypeCultive: 0,
            ClpID: 0,
            itemTableDoll: [],
            DtbID: 0,
            newRcfID:{},
		}),
 
		methods: {
            addSampling(){
                
                if(this.newItem.ObjTypeDefects == undefined){
                    this.$fun.alert("Ingrese tipo de defecto", "warning");
                    return;
                }
                if(!this.newItem.Psgvalue){
                    this.$fun.alert("Ingrese Valor", "warning");
                    return;
                }
                let newItems = {};
                newItems.DfcID = this.DfcID;
                newItems.DfcName = this.DfcName;
                newItems.Psgvalue = this.newItem.Psgvalue;
                newItems.Item = this.itemsDataSampl.length + 1;
                newItems.SecStatus =  1;

                this.itemsDataSampl.push(structuredClone(newItems));
                //this.itemsDataSampl.push({...newItems});
                this.$refs.focusFafValue.focus();
                this.newItem.Psgvalue = 0

                this.newItems = {};

                
            },
            loadItems(filter){
                if(this.DtbID > 0){
                    this.filter = {
                        DtbID: this.DtbID,
                    }
                    _sProcessSampling.list(this.filter, this.$fun.getUserID())
                    .then((r) => {
                        if (r.status == 200) {
                            this.items = r.data;
                            
                        }
                    })
                }
                
            },

            // loadDamage(){
            //     _sDamageFlawCultiveService.list(this.filter, this.$fun.getUserID())
            //     .then((r)=>{
            //         if(r.status == 200){
            //             console.log('daños por cultivo', r.data);
            //             this.DamageCultive = r.data;
            //         }
            //     });
            // },
            inputDamage(item){
                console.log("inputDamage-->", item);
                if(item != undefined){
                    this.DfcID = item ? item.DfcID : "";
                    this.DfcName = item ? item.DfcName : "";  
                }
                // this.TypeCultive = item ? item.TypeCultive : "";
            },
            // inputDamageTC(rcfid){
                
                
            //     if(rcfid != undefined){
            //     // TypeCultive = this.itemRcfid;
            //         let itemS = this.itemRcfid.filter((x) => x.RcfID == rcfid)
                   
        
            //         _sDamageFlawCultiveService.list({TypeCultive : itemS[0].TypeCultive}, this.$fun.getUserID()).then(r =>{
            //             if(r.status == 200)
            //             {
            //                 this.DamageCultive = r.data; 
                                        
            //             }
            //         });   
            //     }
            // },

            initialize()
            {
                let filter = {
                    // DtbID: this.DtbID,
                    // TypeCultive: this.TypeCultive
                    ClpID : this.ClpID,//3319,
                    DtbID : this.DtbID

                }
                console.log ('filtra',filter)
                // return
                _sProcessSampling.lst(filter, this.$fun.getUserID()).then(r =>{
                    if(r.status == 200)
                    {
                        this.itemRcfid = r.data;                                
                    }
                });            
            },
           
            save(item) {
               
                if(item.ProccesGreen == 0)
                {
                    this.$fun.alert("Ingrese Cantidad de Verde proceso", "warning");
                    this.$refs.txtProccesGreen.focus();
                    return;
                }
                if(item.Cork == 0)
                {
                    this.$fun.alert("Ingrese Cantidad de Corcho", "warning");
                    this.$refs.txtCork.focus();
                    return;
                }
                /**validar que el grupo tenga integrantes */
                if(this.itemsDataSampl.length <= 0 ){
                    this.$fun.alert("Debe ingresar defectos", "warning");
                    this.$refs.txtDamage.focus();
                    return;
                }
                if(!item.StartTimeCD){
                    this.$fun.alert("Ingresar Hora Inicio Control Descarte ", "warning");
                    this.$refs.txtStartTimeCD.focus();
                    return;
                }
                if(!item.EndTimeCD){
                    this.$fun.alert("Ingresar Hora Final Control Descarte ", "warning");
                    this.$refs.txtEndTimeCD.focus();
                    return;
                }
                if(!item.ProcessGreenKG){
                    this.$fun.alert("Ingresar Kg Verde Proceso ", "warning");
                    this.$refs.txtProcessGreenKG.focus();
                    return;
                }
                if(!item.CorkKG){
                    this.$fun.alert("Ingresar Kg Corcho", "warning");
                    this.$refs.txtCorkKG.focus();
                    return;
                }
                /** fin de validar */
                item.RcfID = this.newRcfID.RcfID;
                item.TypeCultive = this.TypeCultive;
                item.ClpID = this.ClpID; 
                item.DtbID = this.DtbID;

              

                this.itemsDamageAdd = [...this.itemsDataSampl, ...this.itemsDeleteDamage];
                // this.itemsDamageAdd = [...this.itemsDataSampl];

                item.FrzProcessSamplingDetail = this.itemsDamageAdd;

                console.log("a guardar", item)
                // return
                item.save().then((r) => {
                if (r.status == 200) {
                    this.$fun.alert("Registrado correctamente", "success");
                    this.$emit("close");

                    this.itemsDataSampl = []
                }
                });
            },
            
 
            clickDeleteDamage(item) {
               
                this.itemsDataSampl = this.itemsDataSampl.filter(
                    (x) => {
                    return x.Item != item.Item
                    }
                );
                item.SecStatus = 0;
                if(item.PsgID > 0)
                {
                    this.itemsDeleteDamage.push({ ...item} );
                    
                }
                
            },
           
            rowSelected(item){
                console.log("rowSelected-->", item);
                this.itemsDataSampl = []
                //console.log( { item } )

                if(item.length == 0)
                {
                    return;
                }

                _sProcessSampling.listdetail({ PcsID: item[0].PcsID }, this.$fun.getUserID())
                .then((r) => {
                    if (r.status == 200) {

                        //this.PgdID = r.data.PgdID;
                        r.data.forEach((el, index) => {
                            el.Item = index + 1;
                        });

                        this.itemsDataSampl = r.data;
                    }
                   
                })
            },            
            TurnActive(){
                let TypeArea = 4
                _sTurnService.turnActive({TypeArea : TypeArea},this.$fun.getUserID()).then(r =>{
                        if(r.status == 200)
                        {
                            this.Turn = r.data;           
                                  
                        }
                    });      
            },
            getLines(item){
                // console.log('Type cultivo',item);
                if(item > 0){

                    this.DtbID = 0;
                
                    let processInitial = {
                        TypeCultive: item,
                        TrnID: this.Turn[0].TrnID

                    }
                    
                    _sFrzCtnLinesProcessService.listLineas(processInitial, this.$fun.getUserID()).then(r => {
                        if(r.status == 200){
                            this.itemsLineas = r.data;     
                        }
                    })
                    // this.initialize();
                }

                if(item != undefined){
                // TypeCultive = this.itemRcfid;
                    // let itemS = this.itemRcfid.filter((x) => x.RcfID == rcfid)
                   
        
                    _sDamageFlawCultiveService.list({TypeCultive : item}, this.$fun.getUserID()).then(r =>{
                        if(r.status == 200)
                        {
                            this.DamageCultive = r.data; 
                                        
                        }
                    });   
                }


            },
            getTablesLine(item){                
                console.log("getTablesLine-->", item);
                if(item != 0)
                {                
                    _sFrzCtnLinesProcessService.listTablesByLines({ClpID: item},this.$fun.getUserID()).then(r => {
                        if(r.status == 200){
                            this.itemsTablesLines = r.data
                            
                                this.itemTableDoll = this.itemsTablesLines.filter(x => x.DtbID > 0);           
                                this.initialize();                     
                                
                        }
                    })
                }
            },
            getNumberLote(item)
            {                 
                if(item != 0)
                {
                    this.initialize();
                }
            }
        },
        created () {
			
           
            //this.inputDamageTC(this.typeCultive)
            // this.inputDamageTC(3)
	    },
        mounted(){
            // this.loadItems();
            this.TurnActive();
            // this.item.StartTimeCD = this.$fun.formatTimeShortView(this.$moment().format("hh:mm"));
            // this.item.EndTimeCD = this.$fun.formatTimeShortView(this.$moment().format("hh:mm"));
        }

            
	}
</script>
<style>
.relojito {
	width:auto;
	height: auto;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(160, 159, 159);
	border-radius: 4px;
	padding: 3px;
    margin-top: -2px;
  }
  </style>
